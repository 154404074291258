<template>
	<figure class="input-text-area">
		<label class="admin-default-label primary-text"
			><slot name="label"></slot
		></label>
		<v-select
			:id="id"
			:items="items"
			:item-text="itemText"
			:item-value="itemValue"
			:loading="isLoading"
			outlined
			:rules="rules"
			@input="handleInput"
		></v-select>
	</figure>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "InputSelect",
	props: {
		id: {
			type: String,
			require: true
		},
		items: {
			type: Array,
			require: true
		},
		itemText: {
			type: String,
			require: true
		},
		itemValue: {
			type: String,
			require: true
		},
		value: {},
		label: {
			type: String,
			require: false
		},
		rules: {
			type: Array,
			require: false
		}
	},
	computed: {
		...mapGetters(["isLoading"])
	},
	data() {
		return {
			content: this.value
		};
	},
	methods: {
		handleInput(event) {
			this.$emit("input", event);
		}
	}
};
</script>
