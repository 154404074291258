import { getters } from "./getters.quiz";
import { actions } from "./actions.quiz";
import { mutations } from "./mutations.quiz";

export const quiz = {
	state: () => ({
		quizzes: [],
		deletedQuizzes: []
	}),
	getters,
	actions,
	mutations
};
