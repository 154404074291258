<template>
	<div class="color-picker-input-container">
		<label
			class="admin-default-label primary-text"
			for="color-picker-input "
		>
			{{ label }}
		</label>
		<figure class="color-picker-input" @click="isVisibleToggle">
			<span>{{ value }}</span>
			<span
				class="color-picker-input-rectangle"
				:style="{ backgroundColor: value }"
			></span>
		</figure>
		<v-color-picker
			:id="id"
			:name="name"
			:loading="isLoading"
			v-show="isVisible"
			mode="hexa"
			class="ma-2"
			dot-size="30"
			:value="value"
			v-model="content"
			@input="handleInput"
		></v-color-picker>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "InputColorPicker",
	props: {
		label: { type: String, require: true },
		id: { type: String, require: true },
		name: { type: String, require: true },
		value: {},
		valueName: {
			type: String,
			require: true
		}
	},
	computed: {
		...mapGetters(["theme", "isLoading"])
	},
	data() {
		return {
			isVisible: false,
			content: this.value
		};
	},
	methods: {
		isVisibleToggle() {
			this.isVisible = !this.isVisible;
		},
		handleInput() {
			this.$emit("input", this.content);
		}
	},
	updated() {
		this.$store.dispatch("setSingleValue", {
			key: this.valueName,
			value: this.content
		});
		this.$store.dispatch("setThemeColors");
	}
};
</script>

<style></style>
