export const getters = {
	allQuizzes(context) {
		return context.quizzes;
	},
	publishedQuizzes(context) {
		return context.quizzes.filter(el => el.published_at !== null);
	},
	deletedQuizzes(context) {
		return context.deletedQuizzes;
	}
};
