import { BASE_URL } from "../globals";
import axios from "axios";

export const post = async (url, object, config) => {
	const token = localStorage.getItem("token");
	const formData = new FormData();
	for (const key in object) {
		if (typeof object[key] === "string") {
			formData.append(key, object[key]);
		} else {
			formData.append(key, JSON.stringify(object[key]));
		}
	}

	const response = await axios.post(`${BASE_URL}${url}`, object, {
		...config,
		headers: {
			...(config?.headers || null),
			Authorization: `Bearer ${token}`
		}
	});

	return response;
};
