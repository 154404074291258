export const getters = {
	allCourses(context) {
		return context.courses;
	},
	publishedCourses(context) {
		return context.courses.filter(el => el.published_at !== null);
	},
	draftCourses(context) {
		return context.courses.filter(el => el.published_at === null);
	},
	deletedCourses(context) {
		return context.deletedCourses;
	},
	courseTypes(context) {
		return context.courseTypes;
	},
	startedCourses(context) {
		return context.startedCourses;
	},
	studentAnalytics(context) {
		return context.studentAnalytics;
	}
};
