<template>
	<figure class="input-text-area">
		<label class="admin-default-label primary-text" :for="id"
			><slot name="label"></slot
		></label>
		<v-textarea
			:id="id"
			counter="200"
			:rules="[
				v =>
					(v || '').length <= 200 ||
					'Description must be 200 characters or less'
			]"
			class="rounded-0 mb-5 admin-default-textarea primary-text"
			:loading="isLoading"
			:value="content"
			@input="handleInput"
		>
		</v-textarea>
	</figure>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "InputTextArea",
	props: {
		name: {
			type: String,
			require: true
		},
		id: {
			type: String,
			require: true
		},
		buttonLabel: {
			type: String,
			require: true
		},
		value: {}
	},
	computed: {
		...mapGetters(["isLoading"])
	},
	watch: {
		value: function (val) {
			this.content = val;
		}
	},
	data() {
		return {
			content: this.value
		};
	},
	methods: {
		handleInput(event) {
			this.content = event;
			this.$emit("input", this.content);
		}
	}
};
</script>
