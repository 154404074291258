import { getters } from "./getters.organization";
import { actions } from "./actions.organization";
import { mutations } from "./mutations.organization";

export const organization = {
	state: () => ({
		name: "",
		description: "",
		logo: "",
		headerImage: null,
		theme: "light",
		backgroundColor: "#FFF",
		mainAccentColor: "#96B93D",
		secondaryAccentColor: "#2F91AE",
		linkColor: "#2F91AE",
		homePageHeader: "",
		homePageSubheader: "",
		homePageMainText: "",
		homePageMainImage: "",
		homePageMainBackgroundImage: "",
		userRoles: null,
		currentPlan: null,
		paymentMethod: null,
		billingHistory: null,
		comment: null,
		extras: [],
		organizationId: null,
		mainAdress: "",
		city: "",
		state: "",
		zip: "",
		country: "",
		website: "",
		licenseType: "",
		company: "",
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		phoneExt: "",
		teamMembers: [],
		enabled: "",
		courses: [],
		organizationExtraId: "",
		originTheme: "",
		originBackgroundColor: "",
		originMainAccentColor: "",
		originSecondaryAccentColor: "",
		originLinkColor: ""
	}),
	getters,
	actions,
	mutations
};
