import { getters } from "./getters.course";
import { actions } from "./actions.course";
import { mutations } from "./mutations.course";

export const course = {
	state: () => ({
		courseTypes: [],
		courses: [],
		startedCourses: [],
		deletedCourses: [],
		studentAnalytics: []
	}),
	getters,
	actions,
	mutations
};
