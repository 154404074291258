<template>
	<figure class="input-text-field">
		<label class="admin-default-label primary-text" :for="id"
			><slot name="label"></slot
		></label>

		<v-text-field
			:id="id"
			:name="id"
			:type="type"
			class="rounded-0 admin-default-input"
			solo
			rounded
			single-line
			:loading="isLoading"
			:rules="rules"
			:value="content"
			:append-icon="appendIcon"
			:placeholder="placeholder"
			@input="handleInput"
		></v-text-field>
	</figure>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "InputTextField",
	props: {
		id: { type: String, require: true },
		value: {},
		rules: {
			type: Array,
			require: false
		},
		type: {
			type: String,
			require: false,
			default: "text"
		},
		appendIcon: {
			type: String,
			require: false
		},
		label: {
			type: String,
			require: false
		},
		placeholder: {
			type: String,
			require: false
		}
	},
	computed: {
		...mapGetters(["isLoading"])
	},
	watch: {
		value: function (val) {
			this.content = val;
		}
	},
	data() {
		return {
			color: "",
			isVisible: false,
			content: this.value
		};
	},
	methods: {
		handleInput(event) {
			this.content = event;
			this.$emit("input", this.content);
		},
		isVisibleToggle() {
			this.isVisible = !this.isVisible;
		}
	}
};
</script>

<style></style>
