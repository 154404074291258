import { getters } from "./getters.app";
import { actions } from "./actions.app";
import { mutations } from "./mutations.app";

export const app = {
	state: () => ({
		menuItems: [
			{ title: "Dashboard", href: "/admin", label: "dashboard" },
			{ title: "Courses", href: "/admin/courses", label: "courses" },
			{ title: "Quizzes", href: "/admin/quizes", label: "quizes" },
			{
				title: "Certificates",
				href: "/admin/certificates",
				label: "certificates"
			},
			{
				title: "Organization",
				href: "/admin/organization/settings",
				label: "organization"
			}
		],
		isLoading: false,
		isThemeFetched: false
	}),
	getters,
	actions,
	mutations
};
