<template>
	<h3 class="admin-default-subheader primary-text">
		<slot></slot>
	</h3>
</template>

<script>
export default {
	name: "AdminDefaultSubheader"
};
</script>

<style></style>
