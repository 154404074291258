export const mutations = {
	setUserData(context, payload) {
		context.id = payload.id;
		context.token = payload.token;
		context.roles = payload.roles;
		context.name = payload.name;
		context.firstName = payload.firstName;
		context.lastName = payload.lastName;
		context.email = payload.email;
		context.ownedOrganizations = payload.ownedOrganizations;
		context.belongsToOrganizations = payload.belongsToOrganizations;
	},
	setIsUserLogged(context, payload) {
		context.isLogged = payload;
	}
};
