<template>
	<v-fade-transition mode="out-in">
		<router-view />
	</v-fade-transition>
</template>

<script>
// Styles
import "@/styles/main.scss";
import { mapGetters } from "vuex";

export default {
	name: "App",
	computed: {
		...mapGetters(["theme"])
	},
	metaInfo: {
		title: "WHS-Academy",
		// titleTemplate: "%s | Material Dashboard Free",
		htmlAttrs: { lang: "en" },
		meta: [
			{ charset: "utf-8" },
			{ name: "viewport", content: "width=device-width, initial-scale=1" }
		]
	}
};
</script>

<style>
/* .primary-text {
	color: var(--v-primary-base) !important;
} */

.primary-background {
	background-color: var(--v-background-base) !important;
}

.contrast-text {
	color: var(--v-background-base) !important;
}

.primary-contrast-background {
	background-color: var(--v-primary-base) !important;
}

.secondary-contrast-background {
	background-color: var(--v-secondary-base) !important;
}

.link-text {
	color: var(--v-link-base) !important;
}

.link-background {
	background-color: var(--v-link-base) !important;
}
</style>
