import { get } from "../../../util/requests/get";
import { post } from "@/util/requests/post";
import { put } from "@/util/requests/put";
import Vuetify from "../../../plugins/vuetify";

export const actions = {
	async getOrganizationInfo(context, id) {
		const orgId = id || context.getters.organization.id;
		await context.dispatch("setIsLoading", true);
		const {
			data: { data }
		} = await get(`/organization/${orgId}`);

		context.commit("setOrganizationExtras", {
			extras: data.extra
		});

		context.commit("setOrganizationInfo", {
			...data,
			background_image: null,
			header_image: null,
			logo: null
		});

		if (data.extra === null) {
			await context.dispatch("setIsLoading", false);
			return;
		}
		context.commit("setOrganizationSettingsDetails", {
			theme: data.extra.theme,
			backgroundColor: data.extra.background_color,
			mainAccentColor: data.extra.main_accent_color,
			secondaryAccentColor: data.extra.secondary_accent_color,
			linkColor: data.extra.link_color
		});
		context.commit("setOrganizationSettingsHomePage", {
			homePageHeader: data.extra.main_header,
			homePageSubHeader: data.extra.sub_header,
			homePageMainText: data.extra.main_text,
			homePageMainImage: null,
			comment: data.extra.comment
		});
		context.commit("setOrganizationExtraId", data.extra.id);
		context.commit("setOrganizationInfo", {
			...data
		});
		context.dispatch("setOriginColors", {
			theme: data.extra.theme,
			backgroundColor: data.extra.background_color,
			mainAccentColor: data.extra.main_accent_color,
			secondaryAccentColor: data.extra.secondary_accent_color,
			linkColor: data.extra.link_color
		});
		await context.dispatch("setThemeColors");
		await context.dispatch("setIsLoading", false);
	},

	setOrganizationSettingsDetails(context, payload) {
		context.commit("setOrganizationSettingsDetails", {
			organizationId: payload.organizationId,
			theme: payload.theme,
			backgroundColor: payload.backgroundColor,
			mainAccentColor: payload.mainAccentColor,
			secondaryAccentColor: payload.secondaryAccentColor,
			linkColor: payload.linkColor
		});
	},
	setOrganizationSettingsHomePage(context, payload) {
		context.commit("setOrganizationSettingsHomePage", {
			homePageHeader: payload.homePageHeader,
			homePageSubHeader: payload.homePageSubHeader,
			homePageMainText: payload.homePageMainText,
			homePageMainImage: null,
			comment: payload.comment
		});
	},
	setUserRoles(context, payload) {
		context.commit("setUserRoles", payload);
	},
	setSingleValue(context, payload) {
		context.commit("setSingleValue", payload);
	},
	async saveOrganizationInfo(context) {
		await put("/organization", context.getters.organization.id, {
			display_name: context.state.name,
			description: context.state.description,
			main_address: context.state.mainAdress,
			city: context.state.city,
			state: context.state.state,
			zip: context.state.zip,
			country: context.state.country,
			website: context.state.website,
			license_type: context.state.licenseType,
			company: context.state.company,
			first_name: context.state.firstName,
			last_name: context.state.lastName,
			email: context.state.email,
			phone: context.state.phone,
			phone_ext: context.state.Ext,
			team_members: context.state.teamMembers,
			enabled: context.state.enabled
		});
	},
	async saveOrganizationExtra(context) {
		if (context.state.organizationExtraId) {
			return await put(
				"/organization_extra",
				context.state.organizationExtraId,
				{
					organization_id: context.getters.organization.id,
					theme: context.state.theme,
					background_color: context.state.backgroundColor,
					main_accent_color: context.state.mainAccentColor,
					secondary_accent_color: context.state.secondaryAccentColor,
					link_color: context.state.linkColor,
					main_header: context.state.homePageHeader,
					sub_header: context.state.homePageSubheader,
					main_text: context.state.homePageMainText,
					main_image: context.state.homePageMainImage,
					comment: context.state.comment
				}
			);
		} else {
			return await post("/organization_extra", {
				organization_id: context.getters.organization.id,
				theme: context.state.theme,
				background_color: context.state.backgroundColor,
				main_accent_color: context.state.mainAccentColor,
				secondary_accent_color: context.state.secondaryAccentColor,
				link_color: context.state.linkColor,
				main_header: context.state.homePageHeader,
				sub_header: context.state.homePageSubheader,
				main_text: context.state.homePageMainText,
				main_image: context.state.homePageMainImage,
				comment: context.state.comment
			});
		}
	},
	async saveOrganizationLogo(context) {
		await post(
			"/organization_logo",
			{
				organization_id: context.getters.organization.id,
				comment: "",
				file: context.state.logo
			},
			{ headers: { "Content-Type": "multipart/form-data" } }
		);
	},
	async saveHeaderImage(context) {
		await post(
			"/organization_header_image",
			{
				organization_id: context.getters.organization.id,
				comment: "",
				file: context.state.headerImage
			},
			{ headers: { "Content-Type": "multipart/form-data" } }
		);
	},
	async saveBackgroundImage(context) {
		await post(
			"/organization_background_image",
			{
				organization_id: context.getters.organization.id,
				comment: "",
				file: context.state.homePageMainBackgroundImage
			},
			{ headers: { "Content-Type": "multipart/form-data" } }
		);
	},
	async saveMainImage(context) {
		await post(
			"/organization_header_image",
			{
				organization_id: context.getters.organization.id,
				comment: "",
				file: context.state.headerImage
			},
			{ headers: { "Content-Type": "multipart/form-data" } }
		);
	},
	async saveOrganizationSettingsDetails(context) {
		await context.dispatch("setIsLoading", true);
		if (context.state.logo !== null && !context.state.logo?.file) {
			context.dispatch("saveOrganizationLogo");
		}
		if (
			context.state.headerImage !== null &&
			!context.state.headerImage?.file
		) {
			context.dispatch("saveHeaderImage");
		}
		context.dispatch("saveOrganizationInfo");
		const response = await context.dispatch("saveOrganizationExtra");
		await context.dispatch("setIsLoading", false);
		return response.data;
	},
	async saveOrganizationSettingsHomePage(context) {
		await context.dispatch("setIsLoading", true);

		if (
			!!context.state.homePageMainBackgroundImage &&
			!context.state.homePageMainBackgroundImage?.file
		) {
			context.dispatch("saveBackgroundImage");
		}

		const response = await context.dispatch("saveOrganizationExtra");

		await context.dispatch("setIsLoading", false);
		return response.data;
	},
	setOriginColors(context, payload) {
		context.commit("setOriginColors", payload);
	},
	setThemeColors(context, payload) {
		Vuetify.framework.theme.themes.light = {
			primary: context.getters.theme.mainAccentColor,
			secondary: context.getters.theme.secondaryAccentColor,
			background: context.getters.theme.backgroundColor,
			link: context.getters.theme.linkColor,
			info: "#00CAE3",
			success: "#4CAF50",
			warning: "#FB8C00",
			error: "#FF5252"
		};
	},
	restoreColors(context) {
		context.commit("restoreColors");
	},
	setLogo(context, payload) {
		context.commit("setLogo", payload);
	},
	setHeaderImage(context, payload) {
		context.commit("setHeaderImage", payload);
	},
	setMainImage(context, payload) {
		context.commit("setMainImage", payload);
	},
	setBackgroundImage(context, payload) {
		context.commit("setBackgroundImage", payload);
	}
};
