// Vuetify Documentation https://vuetifyjs.com

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ripple from "vuetify/lib/directives/ripple";
import store from "../store/index";

Vue.use(Vuetify, {
	directives: { ripple }
});

const theme = {
	// primary: store.getters.theme.backgroundColor,
	// secondary: store.getters.theme.secondaryAccentColor,
	// accent: store.getters.theme.mainAccentColor,
	// primary_text: store.getters.theme.backgroundColor,
	// secondary_text: store.getters.theme.secondaryAccentColor,
	// accent_text: store.getters.theme.mainAccentColor,
	info: "#00CAE3",
	success: "#4CAF50",
	warning: "#FB8C00",
	error: "#FF5252"
};

export default new Vuetify({
	breakpoint: { mobileBreakpoint: 960 },
	icons: {
		values: { expand: "mdi-menu-down" }
	},
	options: {
		customProperties: true
	},
	theme: {
		themes: {
			// dark: theme,
			light: theme
		},
		options: { customProperties: true }
	}
});
