export const getters = {
	token: state => {
		return state.token;
	},
	isLogged: state => {
		return state.isLogged;
	},
	isAdmin: state => {
		return !!state.roles?.find(el => el.role_id === 1 || el.role_id === 2);
	},
	organization: state => {
		if (!state.ownedOrganizations) return;
		if (state.belongsToOrganizations && state.belongsToOrganizations.length)
			return state.belongsToOrganizations[0].organization;
		return (
			state.ownedOrganizations[0]?.organization[0] || {
				id: 1
			}
		);
	},
	userId: state => {
		return state.id;
	}
};
