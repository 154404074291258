<template>
	<figure class="input-textarea">
		<label class="admin-default-label primary-text"
			><slot name="label"></slot
		></label>
		<v-combobox
			class="admin-default-input--combobox primary-text"
			:items="items"
			:item-text="itemText"
			:item-value="itemValue"
			:label="label"
			:id="id"
			:name="id"
			:loading="isLoading"
			:value="content"
			@change="$emit('change', $event)"
			@input="handleInput"
		/>
	</figure>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "InputComboBox",
	props: {
		id: {
			type: String,
			require: true
		},
		items: {
			type: Array,
			require: true
		},
		itemText: {
			type: String,
			require: true
		},
		itemValue: {
			type: String,
			require: true
		},
		buttonLabel: {
			type: String,
			require: true
		},
		value: {},

		label: {
			type: String,
			require: false
		}
	},
	computed: {
		...mapGetters(["isLoading"])
	},
	data() {
		return {
			content: this.value
		};
	},
	methods: {
		handleInput(event) {
			this.$emit("input", event);
		}
	}
};
</script>
