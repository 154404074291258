import { get } from "../../../util/requests/get";
import { post } from "@/util/requests/post";
import router from "@/router";

// let idle

// function CustomAlert() {
// 	this.alert = function() {
// 		let timer
// 		let leftTimer
// 		// clearInterval(timer)
// 		timer = setInterval(() => {
// 			console.log(1)
// 		}, 1000)
// 	  document.body.innerHTML = document.body.innerHTML + '<div id="dialogoverlay"></div><div id="dialogbox">You will automatically be logged out due to inactivity in 00:01:03 <a class="btn btn-light shadow-none mt-2 px-3 py-2 rounded-5" @click="resumeSession()">Resume session</a></div>';
// 	}

//   }

// let customAlert = new CustomAlert()

export const actions = {
	async activateOrganization(context, payload) {
		if (payload === undefined) return;
		await post(`/organization_activate_session/${payload}`);
	},
	async login(context, payload) {
		const response = await post("/login", payload, {
			headers: { Authorization: "Bearer NULL" }
		});
		const { data } = response;
		if (data.status !== "success") {
			context.dispatch("throwErrorMessage", data.message);
		}

		const user = {
			token: data.token,
			id: data.user.id,
			roles: data.user.roles,
			name: data.user.name,
			firstName: data.user.first_name,
			lastName: data.user.last_name,
			email: data.user.email,
			ownedOrganizations: data.user.user_owned_organizations,
			belongsToOrganizations: data.user.user_belongs_to_organizations
		};

		const expirationDate =
			new Date().getTime() + +data.session_seconds_left * 1000;

		localStorage.setItem("token", data.token);
		localStorage.setItem("isLogged", true);
		localStorage.setItem(
			"isAdmin",
			!!data.user.roles?.find(el => el.role_id === 1 || el.role_id === 2)
		);
		localStorage.setItem("tokenExpiration", expirationDate);

		localStorage.setItem("user", JSON.stringify(user));

		await context.commit("setUserData", user);
		await context.commit("setIsUserLogged", true);
		await context.dispatch(
			"activateOrganization",
			user.ownedOrganizations.length === 0
				? user.belongsToOrganizations[0]?.organization_id
				: user.ownedOrganizations[0]?.organization_id
		);
		return response;
	},
	async logout(context) {
		try {

			localStorage.clear()

			context.commit("setIsUserLogged", false);
			context.commit("setUserData", {
				token: null,
				roles: null,
				name: null,
				firstName: null,
				lastName: null,
				email: null,
				owned_organizations: null
			});

			window.location.reload(true) // clear cache

			router.push({ name: "Login" });
			await get("/logout");


		} catch (error) {
			console.error(`Error while logout in request: ${error}`);
		}
	},

	async autoLogin(context) {
		const token = localStorage.getItem("token");
		const tokenExpiration = localStorage.getItem("tokenExpiration");
		const user = localStorage.getItem("user");

		// const expiresIn = tokenExpiration - new Date().getTime();

		// clearInterval(idle)
		// idle = setInterval(() => {
		// 	const expiresIn = tokenExpiration - new Date().getTime();
		// 	if (expiresIn < 589990 || !token) {
		// 		clearInterval(idle)
		// 		customAlert.alert()
		// 	} else if (expiresIn < 0 || !token) {
		// 		context.dispatch("logout");
		// 		return;
		// 	}

		// })

		localStorage.setItem("tokenExpiration", new Date().getTime() + 600000);
		context.commit("setUserData", JSON.parse(user));
		await context.commit("setIsUserLogged", true);
		await get("/keep-alive");
	},

	throwErrorMessage(context, message) {
		const error = new Error(
			message || "Failed to authenticate. Check your login data."
		);
		throw error;
	}
};
