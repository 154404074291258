<template>
	<router-link class="text-brand font-weight-bold text-decoration-none" :to="link" :class="elClass">
		<v-icon class="text-brand">mdi-chevron-left</v-icon>
		<u><slot name="text"></slot></u>
	</router-link>
</template>

<script>
export default {
	name: "BackTo",
	props: {
		link: {
			type: String,
			require: true
		},
		elClass: {
			type: String,
			require: true
		}
	}
};
</script>
