import { get } from "../../../util/requests/get";

export const actions = {
	async getQuizzes(context) {
		try {
			const {
				data: { data }
			} = await get("/quizzes");
			context.commit("setQuizzes", data);
		} catch (error) {
			console.error(error);
		}
	},
	async getDeletedQuizzes(context) {
		try {
			const {
				data: { data }
			} = await get("/quizzes?only_trashed=true");
			context.commit("setDeletedQuizzes", data);
		} catch (error) {
			console.error(error);
		}
	}
};
