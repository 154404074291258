import { get } from "../../../util/requests/get";
import { post } from "@/util/requests/post";
import router from "@/router";

export const actions = {
	async getCoursesTypes(context) {
		try {
			const {
				data: { data }
			} = await get("/course_types");
			context.commit("setCourseTypes", data);
		} catch (error) {
			console.error(error);
		}
	},
	async getCourses(context) {
		try {
			const {
				data: { data }
			} = await get("/courses");
			context.commit("setCourses", data);
		} catch (error) {
			console.error(error);
		}
	},
	async getDeletedCourses(context) {
		try {
			const {
				data: { data }
			} = await get("/courses?only_trashed=true");
			context.commit("setDeletedCourses", data);
		} catch (error) {
			console.error(error);
		}
	},
	async getStartedCourses(context) {
		try {
			const {
				data: { data }
			} = await get("/student_courses");
			context.commit("setStartedCourses", data);
		} catch (error) {
			console.error(error);
		}
	},
	async getStudentAnalytics(context, payload = "") {
		try {
			const {
				data: { data }
			} = await get(`/student_courses_analytics${payload}`);
			context.commit("setStudentAnalytics", data);
		} catch (error) {
			console.error(error);
		}
	}
};
