<template>
	<figure class="mb-8">
		<span class="admin-default-label primary-text"
			><slot name="span"></slot
		></span>
		<div class="image-file-input">
			<div class="image-file-input-container">
				<v-file-input
					:id="id"
					chips
					truncate-length="50"
					:accept="
						accept || 'image/png, image/jpeg, image/bmp, image/jpg,'
					"
					:loading="isLoading"
					:class="hasFile"
					@change="handleInput"
				></v-file-input>
				<label
					:for="id"
					class="rounded-3 btn btn-light shadow-none py-3 px-4"
					><slot name="button"></slot>
				</label>
			</div>
		</div>
	</figure>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "InputImageFile",
	props: {
		name: {
			type: String,
			require: true
		},
		id: {
			type: String,
			require: true
		},
		accept: {
			type: String,
			require: false
		},
		value: {}
	},
	computed: {
		...mapGetters(["isLoading"])
	},
	watch: {
		value: function (val) {
			this.content = val;
		}
	},
	data() {
		return {
			content: this.value,
			hasFile: 'd-none'
		};
	},
	methods: {
		handleInput(event) {
			this.hasFile = ''
			this.$emit("input", event);
			this.$emit("change", event);
			this.content = event;
		}
	}
};
</script>

<style></style>
