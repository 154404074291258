import { BASE_URL } from "../globals";
import axios from "axios";

export const put = async (url, id, object, config) => {
	const token = localStorage.getItem("token");

	const response = await axios.put(`${BASE_URL}${url}/${id}`, object, {
		...config,
		headers: {
			...(config?.headers || null),
			Authorization: `Bearer ${token}`
		}
	});

	return response;
};
