<template>
	<!-- <article class="row-data">
		<figure class="row-data-image-container d-flex flex-row align-center">
			<img class="row-data-image" :src="imageUrl" alt="" />
		</figure>
		<p class="row-data-name primary-text">{{ name }}</p>
		<p class="row-data-date row-data-date--left primary-text">
			{{ dateLeft }}
		</p>
		<p class="row-data-date row-data-date--right primary-text">
			{{ dateRight }}
		</p>
		<figure class="row-data-buttons-container">
			<button
				class="row-data-button primary-text"
				v-for="(btn, index) in buttons"
				:key="index"
				@click="btn.action"
			>
				{{ btn.title }}
			</button>
		</figure>
	</article> -->
	<tr class="align-middle">
		<td>
			<img class="row-data-image row-thumnail-border" :class="{ hasImage: hasImage }" :src="imageUrl">
		</td>
		<td class="font-weight-bold">{{ name }}</td>
		<td v-if="dateLeft" v-html="dateLeft" class="text-center"></td>
		<td v-if="dateRight" v-html="dateRight" class="text-center"></td>
		<td class="text-right">
			<button
				class="btn shadow-none text-brand font-weight-bold"
				v-for="(btn, index) in buttons"
				:key="index"
				@click="btn.action"
			>
				<u>{{ btn.title }}</u>
			</button>
		</td>
	</tr>
</template>

<script>
export default {
	data() {
		return {
			hasImage: false
		}
	},
	name: "RowData",
	props: {
		image: {
			type: Array,
			require: false,
			default: () => []
		},
		name: {
			type: String,
			require: true
		},
		dateLeft: {
			type: String,
			require: true
		},
		dateRight: {
			type: String,
			require: true
		},
		td1Class: {
			type: String,
			require: false
		},
		buttons: {
			type: Array,
			require: true
		}
	},
	computed: {
		imageUrl() {
			let imgsrc = require('../assets/noimage.svg')
			if(this.image[0] != undefined) {
				this.hasImage = true
				imgsrc = this.image[0]?.image.public_url
			}

			return imgsrc

			// if (this.image[0] === undefined) return "/";
			// return this.image[0]?.image.public_url;
		}
	}
};
</script>

<style></style>
