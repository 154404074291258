<template>
	<v-alert class="mt-6 mb-6" :type="type">
		<slot></slot>
	</v-alert>
</template>

<script>
export default {
	name: "FormMessage",
	props: {
		type: {
			type: String,
			require: false,
			default: "error"
		}
	}
};
</script>

<style></style>
