import { getters } from "./getters.user";
import { actions } from "./actions.user";
import { mutations } from "./mutations.user";

export const user = {
	state: () => ({
		isLogged: localStorage.getItem("isLogged") || false,
		id: null,
		token: null,
		roles: null,
		name: null,
		firstName: null,
		lastName: null,
		email: null,
		ownedOrganizations: null,
		belongsToOrganizations: null
	}),
	getters,
	actions,
	mutations
};
