export const getters = {
	organizationExtras: state => {
		return state.extras;
	},
	theme: state => {
		return {
			backgroundColor: String(state.backgroundColor).slice(0, 7),
			mainAccentColor: String(state.mainAccentColor).slice(0, 7),
			secondaryAccentColor: String(state.secondaryAccentColor).slice(
				0,
				7
			),
			linkColor: String(state.linkColor).slice(0, 7)
		};
	}
	// return
};
