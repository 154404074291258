<template>
	<p class="primary-text">
		<slot></slot>
	</p>
</template>

<script>
export default {
	name: "AdminDefaultDescription"
};
</script>

<style></style>
