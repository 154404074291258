<template>
	<h2 class="admin-default-header primary-text">
		<slot></slot>
	</h2>
</template>

<script>
export default {
	name: "AdminDefaultHeader"
};
</script>

<style></style>
