export const mutations = {
	setOrganizationSettingsDetails(context, payload) {
		context.organizationId = payload.organizationId;
		context.theme = payload.theme;
		context.backgroundColor = payload.backgroundColor;
		context.mainAccentColor = payload.mainAccentColor;
		context.secondaryAccentColor = payload.secondaryAccentColor;
		context.linkColor = payload.linkColor;
	},
	setOrganizationSettingsHomePage(context, payload) {
		context.homePageHeader = payload.homePageHeader;
		context.homePageSubheader = payload.homePageSubHeader;
		context.homePageMainText = payload.homePageMainText;
		context.homePageMainImage = payload.homePageMainImage;
		context.comment = payload.comment;
	},
	setUserRoles(context, payload) {
		context.userRoles = payload;
	},
	setSingleValue(context, payload) {
		context[payload.key] = payload.value;
	},
	setOrganizationExtras(context, payload) {
		context.extras = payload.extras;
	},
	setOrganizationInfo(context, payload) {
		context.organizationId = payload.id;
		context.name = payload.display_name;
		context.description = payload.description;
		context.mainAdress = payload.main_address;
		context.city = payload.city;
		context.state = payload.state;
		context.zip = payload.zip;
		context.country = payload.country;
		context.website = payload.website;
		context.licenseType = payload.license_type;
		context.company = payload.company;
		context.firstName = payload.first_name;
		context.lastName = payload.last_name;
		context.email = payload.email;
		context.phone = payload.phone;
		context.phoneExt = payload.phone_ext;
		context.teamMembers = payload.team_members;
		context.enabled = payload.enabled;
		context.homePageMainBackgroundImage = payload.background_image;
		context.headerImage = payload.header_image;
		context.logo = payload.logo;
		context.courses = payload.courses;
	},
	setOrganizationExtraId(context, payload) {
		context.organizationExtraId = payload;
	},
	setLogo(context, payload) {
		context.logo = payload;
	},
	setHeaderImage(context, payload) {
		context.headerImage = payload;
	},
	setMainImage(context, payload) {
		context.homePageMainImage = payload;
	},
	setBackgroundImage(context, payload) {
		context.homePageMainBackgroundImage = payload;
	},
	setOriginColors(context, payload) {
		context.originTheme = payload.theme;
		context.originBackgroundColor = payload.backgroundColor;
		context.originMainAccentColor = payload.mainAccentColor;
		context.originSecondaryAccentColor = payload.secondaryAccentColor;
		context.originLinkColor = payload.linkColor;
	},
	restoreColors(context) {
		context.theme = context.originTheme;
		context.backgroundColor = context.originBackgroundColor;
		context.mainAccentColor = context.originMainAccentColor;
		context.secondaryAccentColor = context.originSecondaryAccentColor;
		context.linkColor = context.originLinkColor;
	}
};
