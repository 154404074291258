export const mutations = {
	setCourseTypes(context, payload) {
		context.courseTypes = payload;
	},
	setCourses(context, payload) {
		context.courses = payload;
	},
	setDeletedCourses(context, payload) {
		context.deletedCourses = payload;
	},
	setStartedCourses(context, payload) {
		context.startedCourses = payload;
	},
	setStudentAnalytics(context, payload) {
		context.studentAnalytics = payload;
	}
};
