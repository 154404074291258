<template>
	<v-menu min-width="230px" rounded offset-y>
		<template v-slot:activator="{ on }">
			<v-btn icon x-large v-on="on">
				<v-avatar class="primary-contrast-background" size="48">
					<span class="white--text text-h5">{{ userInitials }}</span>
				</v-avatar>
			</v-btn>
		</template>
		<v-card>
			<v-list-item-content class="justify-center student-menu">
				<div class="mx-auto text-center">
					<v-avatar class="primary-contrast-background">
						<span class="white--text text-h5">{{
							userInitials
						}}</span>
					</v-avatar>
					<h4>{{ userFullName }}</h4>
					<h5 v-if="isAdmin">Administrator</h5>
					<p class="text-caption mt-1">
						{{ userEmail }}
					</p>

					<v-divider class="my-3"></v-divider>
					<v-btn @click="userLogout" depressed rounded text>
						Log Out
					</v-btn>
				</div>
			</v-list-item-content>
		</v-card>
	</v-menu>
</template>
<script>
import { mapState } from "vuex";

export default {
	name: "DropdownUser",

	data() {
		return {};
	},
	computed: {
		...mapState({
			userFullName: state => state.user.name,
			userEmail: state => state.user.email
		}),
		logoImage() {
			return this.$store.state.organization.logo?.file?.public_url;
		},
		isAdmin() {
			return this.$store.getters.isAdmin === true;
		},
		userInitials(speaker) {
			const name = this.userFullName?.split(" ");
			return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ""}`;
		}
	},
	methods: {
		userLogout() {
			this.$store.dispatch("logout");
		}
	}
};
</script>
