const HOST = location.host;
let BASE_API = "https://stg.portal.whs.academy/api/v1";
if (HOST !== "stg.whs.academy") {
	BASE_API = "https://dev.portal.whs.academy/api/v1";
}

const EN_LOCALE_ONLY = process.env.EN_LOCALE_ONLY === "true";
const IN_BROWSER = typeof window !== "undefined";
const IS_DEBUG = process.env.DEBUG === "true";
const IS_PROD = process.env.NODE_ENV === "production";
// const BASE_URL = process.env.VUE_APP_BASE_URL;
const BASE_URL = BASE_API;

module.exports = {
	EN_LOCALE_ONLY,
	IN_BROWSER,
	IS_DEBUG,
	IS_PROD,
	BASE_URL
};
